import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside } from "../../../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <h2>{`Description`}</h2>
        <p>{`The USB Function Controller is a flexible solution to add USB functionality to
virtually any application. It includes all functionality required to implement
a USB bus interface within a single FPGA or ASIC device with only an external
USB Transceiver required. Up to four Endpoints are supported, each of them
easily attachable to standard micro controller busses.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "905px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/9dde67c5984eafe413ad211213ebcc5b/65d79/usb-controller_med_hr.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "40%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRUlEQVQoz1VS7XKDIBDk/d8v/RFTiFNBMYIoxs+Y7SytTnsz58nB7t0tCADoY4RzHq/Xi0u83+8zHk7jvnMOxpSIMWLbNgzDgK+iQN/32PcdYhpHhBDgfYvn85mSJOCBv0awtRZZdsPl8oE8z9H3EfM8JzyxXddB8FNVFQqt8Xg80sY0TbC2xjiOZ3fMtW2LoijwKSUqa7FuG35HSSERkv3oSBsDbTSMMZjnJVX33idiFiPpD34/u1b3O67XDPc8R9M0EPxwQSchCcqyhFIqjciqBJKMBfjPSGmYy243ZFkGqVTCCYrrvEcbQhKaI9NJTMHXdT1HpnMaErJrnpdSQkr105DWEEMcME8T6rpGWVVJT1vXaJxLBCQ/jHleBnWkVCzIyBwb4AULgtgFNQyhS5HPiM78siz/bpoFjDbp+RxP6pCA9g3hi2UFJ43BmAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "USB Serial Interface Engine",
                "title": "USB Serial Interface Engine",
                "src": "/static/9dde67c5984eafe413ad211213ebcc5b/65d79/usb-controller_med_hr.png",
                "srcSet": ["/static/9dde67c5984eafe413ad211213ebcc5b/5a46d/usb-controller_med_hr.png 300w", "/static/9dde67c5984eafe413ad211213ebcc5b/0a47e/usb-controller_med_hr.png 600w", "/static/9dde67c5984eafe413ad211213ebcc5b/65d79/usb-controller_med_hr.png 905w"],
                "sizes": "(max-width: 905px) 100vw, 905px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Features`}</p>
        <ul>
          <li parentName="ul">{`fully Compliant to USB 1.1 specification`}
            <ul parentName="li">
              <li parentName="ul">{`Full-Speed (12Mbps) operation`}</li>
              <li parentName="ul">{`support for 4 endpoints, including up to 3 user-configurable Endpoints`}</li>
              <li parentName="ul">{`supports Bulk, Interrupt, or Isochronous data transfers`}</li>
            </ul>
          </li>
          <li parentName="ul">{`hardwired USB protocol layer`}
            <ul parentName="li">
              <li parentName="ul">{`no firmware intervention required`}</li>
              <li parentName="ul">{`up to 10Mbps net bandwidth`}</li>
            </ul>
          </li>
          <li parentName="ul">{`very compact design`}
            <ul parentName="li">
              <li parentName="ul">{`on-chip digital PLL`}</li>
              <li parentName="ul">{`on-chip Endpoint-FIFOs`}</li>
              <li parentName="ul">{`minimum gate count`}</li>
              <li parentName="ul">{`optimized for FPGA implementation`}</li>
            </ul>
          </li>
          <li parentName="ul">{`lowest possible design risk`}
            <ul parentName="li">
              <li parentName="ul">{`free behavioral model`}</li>
              <li parentName="ul">{`comprehensive reference application`}</li>
              <li parentName="ul">{`USB Packet-oriented test bench`}</li>
              <li parentName="ul">{`synthesizable VHDL model`}</li>
            </ul>
          </li>
          <li parentName="ul">{`very low cost`}</li>
        </ul>
        <h2>{`Synthesis`}</h2>
        <p>{`The core was successfully synthesized with the following tools: Synopsys FPGA
Express, Xilinx XST, Mentor Leonardo Spectrum. Resource usage depends on the
endpoint configuration and size of the endpoint FIFOs. This is a summary of the
required resources for a minimal HID design:`}</p>
        <ul>
          <li parentName="ul">{`4 input LUTs: 337`}</li>
          <li parentName="ul">{`3 input LUTs: 52`}</li>
          <li parentName="ul">{`CLB Flip Flops: 203`}</li>
          <li parentName="ul">{`16×1 RAMs: 8`}</li>
          <li parentName="ul">{`gate count: about 4k`}</li>
        </ul>
        <h2>{`Verification`}</h2>
        <p>{`The USB core was verified on different levels:`}</p>
        <ul>
          <li parentName="ul">{`sub-module verification of critical entities, e.g. clock PLL`}</li>
          <li parentName="ul">{`simulation of critical USB packet sequences`}</li>
          <li parentName="ul">{`simulation of full USB device enumeration`}</li>
          <li parentName="ul">{`real-life debugging with USB protocol analyzer`}</li>
          <li parentName="ul">{`real-life test with reference applications`}</li>
        </ul>
        <p>{`The following reference designs have been implemented:`}</p>
        <ul>
          <li parentName="ul">{`HID reference design`}</li>
          <li parentName="ul">{`flash programming application for FPGA development board`}</li>
          <li parentName="ul">{`USB to CompactFlash adapter`}</li>
        </ul>
      </Main>
      <Aside mdxType="Aside">
        <p>{`Resources:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "db-usbsie.pdf"
            }}>{`product specification`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "um-te-usb-tb.pdf"
            }}>{`user’s guide`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "te-usb.pdf"
            }}>{`architectural description`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "an-usb-xsv.pdf"
            }}>{`application note`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "an-usbsie.pdf"
            }}>{`evaluation kit`}</a></li>
        </ul>
      </Aside>
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      